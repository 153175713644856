import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import App from "./components/App";
import Firebase, { FirebaseContext } from "./components/Firebase";

import "fontsource-roboto";
import {
  MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core";

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      primary: {
        light: "#eaeeef",
        main: "#58d5e3",
        dark: "#3795a0",
        contrastText: "#000000",
      },
      // secondary: {
      //   light: "#9f86c0",
      //   main: "#5e548e",
      //   dark: "#231942",
      //   contrastText: "#ffffff",
      // },
      secondary: {
        light: "#f4eafb",
        main: "#f5c6d9",
        dark: "#5f6f71",
        contrastText: "#000000",
      },
    },
  })
);

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <FirebaseContext.Provider value={new Firebase()}>
        <App />
      </FirebaseContext.Provider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
