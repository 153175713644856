import React from "react";
import { withFirebase } from "../Firebase";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./overrides.css";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(2),
    maxWidth: 345,
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  center: {
    justifyContent: "center",
  },
  content: {
    textAlign: "left",
    padding: theme.spacing(2),
  },
  // divider: {
  // margin: `${theme.spacing(3)}px 0`,
  // },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  imageContainer: {
    height: 320,
    maxWidth: 345,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
  },
  imageSize: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  button: {
    textTransform: "none",
  },
  gridItem: {
    width: "100%",
  },
  subGridItemOne: {
    width: "50%",
    paddingRight: theme.spacing(1),
  },
  subGridItemTwo: {
    width: "50%",
    paddingLeft: theme.spacing(1),
  },
  secondaryLight: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const StorePageBase = (props) => {
  const [items, setItems] = React.useState({
    loading: false,
    items: [],
    limit: 12,
    startAtIndex: 0,
  });

  React.useEffect(() => {
    console.log("effect");

    setItems((prevItems) => ({ ...prevItems, loading: true }));

    // const unsubscribe = props.firebase
    props.firebase
      .items()
      .orderBy("createdAt", "desc")
      .limit(items.limit)
      .get()
      .then((snapshot) => {
        console.log("QUERY");
        if (snapshot.size) {
          let items = [];
          snapshot.forEach((doc) => items.push({ ...doc.data(), uid: doc.id }));

          setItems((prevItems) => ({
            ...prevItems,
            items: items.reverse(),
            loading: false,
          }));
        } else {
          setItems((prevItems) => ({
            ...prevItems,
            items: null,
            loading: false,
          }));
        }
      });

    // return () => {
    // unsubscribe();
    // };
  }, [props.firebase, items.limit]);

  const classes = useStyles();

  const onNextPage = () => {
    if (items.items.length === items.startAtIndex + items.limit) {
      const startAtIndex = items.startAtIndex + items.limit;
      setItems((prevItems) => ({
        ...prevItems,
        loading: true,
        startAtIndex: startAtIndex,
      }));

      const startAt = items.items[items.startAtIndex].createdAt;
      props.firebase
        .items()
        .orderBy("createdAt", "desc")
        .startAt(startAt)
        .limit(items.limit)
        .get()
        .then((snapshot) => {
          console.log("QUERY");
          if (snapshot.size) {
            let items = [];
            snapshot.forEach((doc) =>
              items.push({ ...doc.data(), uid: doc.id })
            );

            setItems((prevItems) => ({
              ...prevItems,
              items: items.reverse(),
              loading: false,
            }));
          } else {
            setItems((prevItems) => ({
              ...prevItems,
              items: null,
              loading: false,
            }));
          }
        });
    }
  };

  const onImageClick = (event) => {
    console.log(event.target.innerText === "ADD TO CART");
  };

  return (
    <div>
      <div className={classes.root}>
        <Typography variant="h3" color="primary">
          Items in Stock:
        </Typography>
        <div>
          {items.items ? (
            <ItemsList
              classes={classes}
              items={items.items}
              // onEditMessage={onEditMessage}
              // onRemoveMessage={onRemoveMessage}
              onImageClick={onImageClick}
            />
          ) : (
            <div>There aren't currently any items available</div>
          )}
          {items.loading && <div>Loading...</div>}
          {!items.loading &&
            items.items &&
            items.items.length === items.startAtIndex + items.limit && (
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={onNextPage}
                fullWidth
              >
                <Typography
                  variant="body1"
                  className={classes.button}
                  color="inherit"
                >
                  Load More Items
                </Typography>
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};

const ItemsList = ({ classes, items, onImageClick }) => (
  <Grid container spacing={2} justify="flex-start" alignItems="center">
    {items.map((item) => (
      <Grid key={item.uid} item xs={12} sm={6} md={4} align="center">
        <Item item={item} classes={classes} onImageClick={onImageClick} />
      </Grid>
    ))}
  </Grid>
);

const Item = ({ classes, item, onImageClick }) => (
  <Card className={classes.card}>
    <CardContent style={{ padding: "0px" }}>
      <Carousel
        showThumbs={false}
        infiniteLoop={item.images.length > 1}
        showStatus={false}
        showIndicators={item.images.length > 1}
      >
        {item.images.map((image, index) => (
          <div
            key={index}
            className={classes.imageContainer}
            onClick={onImageClick}
          >
            <img alt="" src={image} className={classes.imageSize} />
          </div>
        ))}
      </Carousel>
    </CardContent>
    <div onClick={onImageClick} className={classes.secondaryLight}>
      <CardContent className={classes.content}>
        <Typography
          gutterBottom
          variant="h5"
          className={classes.heading}
          component="h2"
        >
          {item.name}
        </Typography>
        <Typography
          variant="caption"
          className={classes.subheading}
          color="textSecondary"
          component="p"
        >
          {item.description}
        </Typography>
      </CardContent>
    </div>
    {/* <Divider className={classes.divider} light /> */}
    <CardActions className={`${classes.center} ${classes.secondaryLight}`}>
      <Grid
        container
        className={classes.gridItem}
        direction="row"
        justify="center"
      >
        <Grid item className={classes.subGridItemOne}>
          <Typography variant="body1" color="inherit">
            ${parseFloat(item.price).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item className={classes.subGridItemTwo}>
          <Button size="small" color="primary" variant="contained" fullWidth>
            <Typography
              variant="body1"
              className={classes.button}
              color="inherit"
            >
              Add to Cart
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </CardActions>
  </Card>
);

const StorePage = withFirebase(StorePageBase);

export default StorePage;
