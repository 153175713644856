import React from "react";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { withFirebase } from "../Firebase";
import { PasswordResetForm } from "../PasswordReset";
import PasswordChangeForm from "../PasswordChange";

const SIGN_IN_METHODS = [
  {
    id: "password",
    provider: null,
  },
  {
    id: "google.com",
    provider: "googleProvider",
  },
  {
    id: "facebook.com",
    provider: "facebookProvider",
  },
  {
    id: "twitter.com",
    provider: "twitterProvider",
  },
];

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div>
        <h1>Account: {authUser.email}</h1>
        <PasswordResetForm />
        <PasswordChangeForm />
        <LoginManagement authUser={authUser} />
      </div>
    )}
  </AuthUserContext.Consumer>
);

const LoginManagement = withFirebase((props) => {
  const [signIn, setSignIn] = React.useState({
    activeSignInMethods: [],
    error: null,
  });

  const fetchSignInMethods = React.useCallback(() => {
    props.firebase.auth
      .fetchSignInMethodsForEmail(props.authUser.email)
      .then((activeSignInMethods) =>
        setSignIn({ activeSignInMethods: activeSignInMethods, error: null })
      )
      .catch((error) => setSignIn({ activeSignInMethods: [], error: error }));
  }, [props.authUser.email, props.firebase.auth]);

  React.useEffect(() => {
    fetchSignInMethods();
  }, [fetchSignInMethods]);

  const onSocialLoginLink = (provider) => {
    props.firebase.auth.currentUser
      .linkWithPopup(props.firebase[provider])
      .then(fetchSignInMethods)
      .catch((error) => setSignIn({ activeSignInMethods: [], error: error }));
  };

  const onUnlink = (providerId) => {
    props.firebase.auth.currentUser
      .unlink(providerId)
      .then(fetchSignInMethods)
      .catch((error) => setSignIn({ activeSignInMethods: [], error: error }));
  };

  const onDefaultLoginLink = (password) => {
    const credential = props.firebase.emailAuthProvider.credential(
      props.authUser.email,
      password
    );

    props.firebase.auth.currentUser
      .linkWithCredential(credential)
      .then(fetchSignInMethods)
      .catch((error) => setSignIn({ activeSignInMethods: [], error: error }));
  };

  return (
    <div>
      Sign In Methods:
      <ul>
        {SIGN_IN_METHODS.map((signInMethod) => {
          const onlyOneLeft = signIn.activeSignInMethods.length === 1;
          const isEnabled = signIn.activeSignInMethods.includes(
            signInMethod.id
          );

          return (
            <li key={signInMethod.id}>
              {signInMethod.id === "password" ? (
                <DefaultLoginToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={signInMethod}
                  onLink={onDefaultLoginLink}
                  onUnlink={onUnlink}
                />
              ) : (
                <SocialLoginToggle
                  onlyOneLeft={onlyOneLeft}
                  isEnabled={isEnabled}
                  signInMethod={signInMethod}
                  onLink={onSocialLoginLink}
                  onUnlink={onUnlink}
                />
              )}
            </li>
          );
        })}
      </ul>
      {signIn.error && signIn.error.message}
    </div>
  );
});

const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) =>
  isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      Deactivate {signInMethod.id}
    </button>
  ) : (
    <button type="button" onClick={() => onLink(signInMethod.provider)}>
      Link {signInMethod.id}
    </button>
  );

const DefaultLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) => {
  const [password, setPassword] = React.useState({
    passwordOne: "",
    passwordTwo: "",
  });

  const onSubmit = (event) => {
    event.preventDefault();

    onLink(password.passwordOne);
    setPassword({ passwordOne: "", passwordTwo: "" });
  };

  const onChange = (event) =>
    setPassword({ ...password, [event.target.name]: event.target.value });

  const isInvalid =
    password.passwordOne !== password.passwordTwo ||
    password.passwordOne === "";

  return isEnabled ? (
    <button
      type="button"
      onClick={() => onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}
    >
      Deactivate email and {signInMethod.id}
    </button>
  ) : (
    <form onSubmit={onSubmit}>
      <input
        name="passwordOne"
        value={password.passwordOne}
        onChange={onChange}
        type="password"
        placeholder="New Password"
      />
      <input
        name="passwordTwo"
        value={password.passwordTwo}
        onChange={onChange}
        type="password"
        placeholder="Confirm New Password"
      />
      <button disabled={isInvalid} type="submit">
        Link email and {signInMethod.id}
      </button>
    </form>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AccountPage);
