import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./overrides.css";

import { withFirebase } from "../Firebase";
import { withEmailVerification, withAuthorization } from "../Session";
import * as ROLES from "../../constants/roles";
import { compose } from "recompose";

import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(1),
  },
  input: {
    height: "40px",
  },
  inputLabel: {
    height: "40px",
    top: "-6px",
    "&[data-shrink='true']": {
      top: "0px",
    },
  },
  gridItem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  subGridItemOne: {
    width: "50%",
    paddingRight: theme.spacing(1),
  },
  subGridItemTwo: {
    width: "50%",
    paddingLeft: theme.spacing(1),
  },
  button: {
    textTransform: "none",
  },
  upload: {
    display: "none",
  },
  imageContainer: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 320,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  imageSize: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

const INITIAL_STATE = {
  name: "",
  description: "",
  createdAt: "",
  images: [],
  imageUrls: [],
  imageIsSet: false,
  price: "",
  quantity: "",
  error: "",
};

const NewItemPage = (props) => {
  const classes = useStyles();
  const [formData, setFormData] = React.useState(INITIAL_STATE);

  const textFieldProps = ({ onChange }) => ({
    variant: "outlined",
    InputProps: { className: classes.input },
    InputLabelProps: { className: classes.inputLabel },
    fullWidth: true,
    onChange: onChange,
  });

  const onChange = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.value });

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    const filenames = formData.images.map((file) => file.name);

    files.forEach((file) => {
      if (!filenames.includes(file.name)) {
        setFormData((oldForm) => ({
          ...oldForm,
          imageIsSet: true,
          imageUrls: oldForm.imageUrls.concat(URL.createObjectURL(file)),
          images: oldForm.images.concat(file),
        }));
      } else {
        setFormData((oldForm) => ({
          ...oldForm,
          error: { message: "Image already selected" },
        }));
      }
    });

    event.target.value = null;
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (formData.images === []) {
      setFormData((oldForm) => ({
        ...oldForm,
        error: "Invalid File Selected",
      }));
      return;
    }

    let firebaseUrls = [];
    const metadata = {
      customMetadata: {
        ADMIN: "ADMIN",
      },
    };

    formData.images.forEach((image, index) => {
      const uuid = uuidv4();

      const uploadImage = props.firebase.storage
        .ref(`/images/${uuid}`)
        .put(image, metadata);

      uploadImage.on(
        "state_changed",
        (snapshot) => {
          // console.log(snapshot);
        },
        (error) => {
          setFormData((oldForm) => ({
            ...oldForm,
            error: error,
          }));
        },
        () => {
          props.firebase.storage
            .ref("images")
            .child(`${uuid}`)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              firebaseUrls = firebaseUrls.concat(fireBaseUrl);

              if (firebaseUrls.length === formData.images.length) {
                props.firebase
                  .items()
                  .add({
                    name: formData.name,
                    description: formData.description,
                    createdAt: props.firebase.fieldValue.serverTimestamp(),
                    price: formData.price,
                    quantity: formData.quantity,
                    images: firebaseUrls,
                  })
                  .then((item) => {
                    setFormData(INITIAL_STATE);
                  })
                  .catch((error) => {
                    setFormData((oldForm) => ({ ...oldForm, error }));
                  });
              }
            });
        }
      );
    });
  };

  const isInvalid =
    formData.name === "" ||
    formData.description === "" ||
    formData.price === 0 ||
    isNaN(formData.price) ||
    formData.quantity === 0 ||
    Number.isInteger(formData.quantity) ||
    !formData.imageIsSet;

  return (
    <div>
      <form className={classes.form} onSubmit={onSubmit}>
        <Grid container direction="column" spacing={2} alignContent="center">
          <Grid item className={classes.gridItem}>
            <Typography variant="h3" color="primary">
              Create a New Item:
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField
              name="name"
              label="Item Name"
              value={formData.name}
              {...textFieldProps({ onChange })}
            />
          </Grid>
          <Grid item className={classes.gridItem}>
            <TextField
              name="description"
              label="Item Description"
              value={formData.description}
              {...textFieldProps({ onChange })}
            />
          </Grid>
          <Grid
            item
            container
            className={classes.gridItem}
            direction="row"
            justify="center"
          >
            <Grid item className={classes.subGridItemOne}>
              <TextField
                name="price"
                label="Item Price"
                type="number"
                value={formData.price}
                {...textFieldProps({ onChange })}
              />
            </Grid>
            <Grid item className={classes.subGridItemTwo}>
              <TextField
                name="quantity"
                label="Item Quantity"
                type="number"
                value={formData.quantity}
                {...textFieldProps({ onChange })}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <input
              accept="image/*"
              className={classes.upload}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="contained-button-file">
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                component="span"
                fullWidth
              >
                <Typography variant="body1">Select Images</Typography>
              </Button>
            </label>
          </Grid>
          <Grid item className={classes.gridItem} hidden={!formData.imageIsSet}>
            <Carousel
              infiniteLoop={formData.imageUrls.length > 1}
              showStatus={false}
              showIndicators={formData.imageUrls.length > 1}
            >
              {formData.imageUrls.map((image, index) => (
                <div key={index} className={classes.imageContainer}>
                  <img className={classes.imageSize} alt="" src={image} />
                </div>
              ))}
            </Carousel>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button
              disabled={isInvalid}
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              <Typography variant="body1">Create Item</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
      {formData.error && <p>{formData.error.message}</p>}
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase
)(NewItemPage);
