import React from "react";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";

const withEmailVerification = (Component) => {
  const WithEmailVerification = (props) => {
    const [isSent, setIsSent] = React.useState(false);

    const onSendEmailVerification = () => {
      props.firebase.doSendEmailVerification().then(() => setIsSent(true));
    };

    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          needsEmailVerification(authUser) ? (
            <div>
              {isSent ? (
                <p>
                  Confirmation email sent! You should have a confirmation email
                  (check your spam folder!). Refresh this page once you've
                  confirmed it.
                </p>
              ) : (
                <p>
                  Please verify your email. You should have a confirmation email
                  (check your spam folder!). If not we can send you another.
                </p>
              )}
              <button
                type="button"
                onClick={onSendEmailVerification}
                disabled={isSent}
              >
                Send confirmation email
              </button>
            </div>
          ) : (
            <Component {...props} />
          )
        }
      </AuthUserContext.Consumer>
    );
  };

  return withFirebase(WithEmailVerification);
};

const needsEmailVerification = (authUser) =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map((provider) => provider.providerId)
    .includes("password");

export default withEmailVerification;
