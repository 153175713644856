import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "../Firebase";
import { withAuthorization, withEmailVerification } from "../Session";
import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";

const AdminPage = (props) => {
  return (
    <div>
      <h1>Admin</h1>
      <p>The Admin Page is accessible by every signed in admin user.</p>

      <Switch>
        <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
        <Route exact path={ROUTES.ADMIN} component={UserList} />
      </Switch>
    </div>
  );
};

const UserListBase = (props) => {
  const [users, setUsers] = React.useState({ loading: false, users: [] });

  React.useEffect(() => {
    setUsers((oldUsers) => ({ ...oldUsers, loading: true }));

    const unsubscribe = props.firebase.users().onSnapshot((snapshot) => {
      let users = [];

      snapshot.forEach((doc) => users.push({ ...doc.data(), uid: doc.id }));

      setUsers({ loading: false, users: users });
    });

    return () => {
      unsubscribe();
    };
  }, [props.firebase]);

  return (
    <div>
      <h2>Users</h2>
      {users.loading && <div>Loading...</div>}
      <ul>
        {users.users &&
          users.users.map((user) => (
            <li key={user.uid}>
              <span>
                <strong>ID:</strong> {user.uid}
              </span>
              <span>
                <strong>Email:</strong> {user.email}
              </span>
              <span>
                <strong>Username:</strong> {user.username}
              </span>
              <span>
                <Link
                  to={{
                    pathname: `${ROUTES.ADMIN}/${user.uid}`,
                    state: { user },
                  }}
                >
                  Details
                </Link>
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
};

const UserItemBase = (props) => {
  const [user, setUser] = React.useState({
    loading: false,
    user: null,
    ...props.location.state,
  });

  React.useEffect(() => {
    if (user.user) {
      return;
    }

    setUser((oldUser) => ({ ...oldUser, loading: true }));

    const unsubscribe = props.firebase
      .user(props.match.params.id)
      .onSnapshot((snapshot) => {
        setUser((oldUser) => ({
          ...oldUser,
          user: snapshot.data(),
          loading: false,
        }));
      });

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [props.firebase, props.match.params.id, user.user]);

  const onSendPasswordResetEmail = () => {
    props.firebase.doPasswordReset(user.user.email);
  };

  return (
    <div>
      <h2>User ({props.match.params.id})</h2>
      {user.loading && <div>Loading...</div>}

      {user.user && (
        <div>
          <span>
            <strong>ID:</strong> {user.user.uid}
          </span>
          <span>
            <strong>Email:</strong> {user.user.email}
          </span>
          <span>
            <strong>Username:</strong> {user.user.username}
          </span>
          <span>
            <button type="button" onClick={onSendPasswordResetEmail}>
              Send Password Reset
            </button>
          </span>
        </div>
      )}
    </div>
  );
};

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

const UserList = withFirebase(UserListBase);
const UserItem = withFirebase(UserItemBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withFirebase
)(AdminPage);
