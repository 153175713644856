import React from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const PasswordResetPage = () => (
  <div>
    <h1>PasswordReset</h1>
    <PasswordResetForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null,
};

const PasswordResetForm = withFirebase((props) => {
  const [formData, setFormData] = React.useState(INITIAL_STATE);

  const onSubmit = (event) => {
    props.firebase
      .doPasswordReset(formData.email)
      .then(() => {
        setFormData({ ...INITIAL_STATE });
      })
      .catch((error) => {
        setFormData({ ...formData, error });
      });

    event.preventDefault();
  };

  const onChange = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.value });

  const isInvalid = formData.email === "";

  return (
    <form onSubmit={onSubmit}>
      <input
        name="email"
        value={formData.email}
        onChange={onChange}
        type="text"
        placeholder="Email Address"
      />
      <button disabled={isInvalid} type="submit">
        Reset My Password
      </button>

      {formData.error && <p>{formData.error.message}</p>}
    </form>
  );
});

const PasswordResetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_RESET}>Forgot Password?</Link>
  </p>
);

export default PasswordResetPage;

export { PasswordResetForm, PasswordResetLink };
