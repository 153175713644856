import React from "react";
import { useHistory } from "react-router-dom";

import { SignUpLink } from "../SignUp";
import { PasswordResetLink } from "../PasswordReset";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const SignInPage = () => (
  <div>
    <h1>SignIn</h1>
    <SignInForm />
    <SignInGoogle />
    <SignInFacebook />
    <SignInTwitter />
    <PasswordResetLink />
    <SignUpLink />
  </div>
);

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  "auth/account-exists-with-different-credential";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with the email address from this social account already exists.
  Try to login from this account instead and associate your social accounts
  on your personal account page.
`;

const SignInFormBase = (props) => {
  const [formData, setFormData] = React.useState(INITIAL_STATE);
  const history = useHistory();

  const onSubmit = (event) => {
    props.firebase
      .doSignInWithEmailAndPassword(formData.email, formData.password)
      .then(() => {
        setFormData({ ...INITIAL_STATE });
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        setFormData({ ...formData, error });
      });

    event.preventDefault();
  };

  const onChange = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.value });

  const isInvalid = formData.password === "" || formData.email === "";

  return (
    <form onSubmit={onSubmit}>
      <input
        name="email"
        value={formData.email}
        onChange={onChange}
        type="text"
        placeholder="Email Address"
      />
      <input
        name="password"
        value={formData.password}
        onChange={onChange}
        type="password"
        placeholder="Password"
      />
      <button disabled={isInvalid} type="submit">
        Sign In
      </button>

      {formData.error && <p>{formData.error.message}</p>}
    </form>
  );
};

const SignInGoogleBase = (props) => {
  const [error, setError] = React.useState(null);
  const history = useHistory();

  const onSubmit = (event) => {
    props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        if (socialAuthUser.additionalUserInfo.isNewUser) {
          // Create a user in the Firebase database
          return props.firebase.user(socialAuthUser.user.uid).set(
            {
              username: socialAuthUser.user.displayName,
              email: socialAuthUser.user.email,
              roles: {},
            },
            { merge: true }
          );
        }
      })
      .then(() => {
        setError(null);
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        setError(error);
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <button type="submit">Sign in with Google</button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignInFacebookBase = (props) => {
  const [error, setError] = React.useState(null);
  const history = useHistory();

  const onSubmit = (event) => {
    props.firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        if (socialAuthUser.additionalUserInfo.isNewUser) {
          // Create a user in the Firebase database
          return props.firebase.user(socialAuthUser.user.uid).set(
            {
              username: socialAuthUser.additionalUserInfo.profile.name,
              email: socialAuthUser.additionalUserInfo.profile.email,
              roles: {},
            },
            { merge: true }
          );
        }
      })
      .then(() => {
        setError(null);
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        setError(error);
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <button type="submit">Sign in with Facebook</button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

const SignInTwitterBase = (props) => {
  const [error, setError] = React.useState(null);
  const history = useHistory();

  const onSubmit = (event) => {
    props.firebase
      .doSignInWithTwitter()
      .then((socialAuthUser) => {
        if (socialAuthUser.additionalUserInfo.isNewUser) {
          // Create a user in the Firebase database
          return props.firebase.user(socialAuthUser.user.uid).set(
            {
              username: socialAuthUser.additionalUserInfo.profile.name,
              email: socialAuthUser.additionalUserInfo.profile.email,
              roles: {},
            },
            { merge: true }
          );
        }
      })
      .then(() => {
        setError(null);
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        setError(error);
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <button type="submit">Sign in with Twitter</button>

      {error && <p>{error.message}</p>}
    </form>
  );
};

export default SignInPage;

const SignInForm = withFirebase(SignInFormBase);
const SignInGoogle = withFirebase(SignInGoogleBase);
const SignInFacebook = withFirebase(SignInFacebookBase);
const SignInTwitter = withFirebase(SignInTwitterBase);

export { SignInForm, SignInGoogle, SignInFacebook, SignInTwitter };
