import React from "react";
import { Link, useHistory } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

const SignUpPage = () => (
  <div>
    <h1>SignUp</h1>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  isAdmin: false,
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address already exists. Try to
  login with this account instead. If you think the account
  is already used from one of the social logins, try to
  sign in with one of them. Afterwards, associate your 
  accounts on your personal account page.
`;

const SignUpFormBase = (props) => {
  const [formData, setFormData] = React.useState(INITIAL_STATE);
  const history = useHistory();

  const onSubmit = (event) => {
    const roles = {};

    if (formData.isAdmin) {
      roles[ROLES.ADMIN] = ROLES.ADMIN;
    }

    props.firebase
      .doCreateUserWithEmailAndPassword(formData.email, formData.passwordOne)
      .then((authUser) => {
        // Create a user in the Firebase database
        return props.firebase.user(authUser.user.uid).set(
          {
            username: formData.username,
            email: formData.email,
            roles: roles,
          },
          { merge: true }
        );
      })
      .then(() => {
        return props.firebase.doSendEmailVerification();
      })
      .then((authUser) => {
        setFormData({ ...INITIAL_STATE });
        history.push(ROUTES.HOME);
      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        setFormData({ ...formData, error });
      });

    event.preventDefault();
  };

  const onChange = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.value });

  const onChangeCheckbox = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.checked });

  const isInvalid =
    formData.passwordOne !== formData.passwordTwo ||
    formData.passwordOne === "" ||
    formData.email === "" ||
    formData.username === "";

  return (
    <form onSubmit={onSubmit}>
      <input
        name="username"
        value={formData.username}
        onChange={onChange}
        type="text"
        placeholder="Full Name"
      />
      <input
        name="email"
        value={formData.email}
        onChange={onChange}
        type="text"
        placeholder="Email Address"
      />
      <input
        name="passwordOne"
        value={formData.passwordOne}
        onChange={onChange}
        type="password"
        placeholder="Password"
      />
      <input
        name="passwordTwo"
        value={formData.passwordTwo}
        onChange={onChange}
        type="password"
        placeholder="Confirm Password"
      />
      <label>
        Admin:
        <input
          name="isAdmin"
          type="checkbox"
          checked={formData.isAdmin}
          onChange={onChangeCheckbox}
        />
      </label>
      <button disabled={isInvalid} type="submit">
        Sign Up
      </button>

      {formData.error && <p>{formData.error.message}</p>}
    </form>
  );
};

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

export default SignUpPage;

const SignUpForm = withFirebase(SignUpFormBase);

export { SignUpForm, SignUpLink };
