import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navigation from "../Navigation";
import LandingPage from "../Landing";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import PasswordResetPage from "../PasswordReset";
import HomePage from "../Home";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import NewItemPage from "../NewItem";
import StorePage from "../Store";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.light,
    minHeight: "100vh",
  },
}));

const App = () => {
  const classes = useStyles();
  return (
    <Router>
      <div className={classes.main}>
        <Navigation />

        <Route exact path={ROUTES.LANDING} component={LandingPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.NEW_ITEM} component={NewItemPage} />
        <Route path={ROUTES.STORE} component={StorePage} />
      </div>
    </Router>
  );
};
export default withAuthentication(App);
