import React from "react";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { withFirebase } from "../Firebase";

const HomePage = () => (
  <div>
    <h1>Home Page</h1>
    <p>The Home Page is accessible by every signed in user.</p>

    <Messages />
  </div>
);

const MessagesBase = (props) => {
  const [messages, setMessages] = React.useState({
    loading: false,
    messages: [],
    text: "",
    limit: 5,
  });

  React.useEffect(() => {
    setMessages((prevMessages) => ({ ...prevMessages, loading: true }));

    const unsubscribe = props.firebase
      .messages()
      .orderBy("createdAt", "desc")
      .limit(messages.limit)
      .onSnapshot((snapshot) => {
        if (snapshot.size) {
          let messages = [];
          snapshot.forEach((doc) =>
            messages.push({ ...doc.data(), uid: doc.id })
          );

          setMessages((prevMessages) => ({
            ...prevMessages,
            messages: messages.reverse(),
            loading: false,
          }));
        } else {
          setMessages((prevMessages) => ({
            ...prevMessages,
            messages: null,
            loading: false,
          }));
        }
      });

    return () => {
      unsubscribe();
    };
  }, [props.firebase, messages.limit]);

  const onChangeText = (event) => {
    const text = event.target.value;
    setMessages((prevMessages) => ({
      ...prevMessages,
      text: text,
    }));
  };

  const onCreateMessage = (event, authUser) => {
    props.firebase.messages().add({
      text: messages.text,
      userId: authUser.uid,
      createdAt: props.firebase.fieldValue.serverTimestamp(),
    });

    setMessages((prevMessages) => ({ ...prevMessages, text: "" }));

    event.preventDefault();
  };

  const onRemoveMessage = (uid) => props.firebase.message(uid).delete();

  const onEditMessage = (message, text) => {
    const { uid, ...messageSnapshot } = message;

    props.firebase.message(message.uid).set({
      ...messageSnapshot,
      text: text,
      editedAt: props.firebase.fieldValue.serverTimestamp(),
    });
  };

  const onNextPage = () => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      limit: prevMessages.limit + 5,
    }));
  };

  return (
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          {!messages.loading && messages.messages && (
            <button type="button" onClick={onNextPage}>
              Previous Messages
            </button>
          )}
          {messages.loading && <div>Loading...</div>}
          {messages.messages ? (
            <MessageList
              authUser={authUser}
              messages={messages.messages}
              onEditMessage={onEditMessage}
              onRemoveMessage={onRemoveMessage}
            />
          ) : (
            <div>There aren't currently any messages</div>
          )}

          <form onSubmit={(event) => onCreateMessage(event, authUser)}>
            <input type="text" value={messages.text} onChange={onChangeText} />
            <button type="submit">Send</button>
          </form>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

const MessageList = ({
  authUser,
  messages,
  onEditMessage,
  onRemoveMessage,
}) => (
  <ul>
    {messages.map((message) => (
      <MessageItem
        authUser={authUser}
        key={message.uid}
        message={message}
        onEditMessage={onEditMessage}
        onRemoveMessage={onRemoveMessage}
      />
    ))}
  </ul>
);

const MessageItem = ({ authUser, message, onEditMessage, onRemoveMessage }) => {
  const [edit, setEdit] = React.useState({
    editMode: false,
    editText: message.text,
  });

  const onToggleEditMode = () =>
    setEdit((oldEdit) => ({
      editMode: !oldEdit.editMode,
      editText: message.text,
    }));

  const onChangeEditText = (event) => {
    const text = event.target.value;
    setEdit((oldEdit) => ({ ...oldEdit, editText: text }));
  };

  const onSaveEditText = () => {
    onEditMessage(message, edit.editText);
    setEdit((oldEdit) => ({ ...oldEdit, editMode: false }));
  };

  return (
    <li>
      {edit.editMode ? (
        <input type="text" value={edit.editText} onChange={onChangeEditText} />
      ) : (
        <span>
          <strong>{message.userId}</strong> {message.text}
          {message.editedAt && <span> (Edited) </span>}
        </span>
      )}

      {authUser.uid === message.userId && (
        <span>
          {edit.editMode ? (
            <span>
              <button onClick={onSaveEditText}>Save</button>
              <button onClick={onToggleEditMode}>Reset</button>
            </span>
          ) : (
            <button onClick={onToggleEditMode}>Edit</button>
          )}
          {!edit.editMode && (
            <button type="button" onClick={() => onRemoveMessage(message.uid)}>
              Delete
            </button>
          )}
        </span>
      )}
    </li>
  );
};

const Messages = withFirebase(MessagesBase);

const condition = (authUser) => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(HomePage);
