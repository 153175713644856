import React from "react";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
// import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(2),
    maxWidth: 345,
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  media: {
    height: 140,
    paddingTop: "56.25%",
  },
  center: {
    justifyContent: "center",
  },
  content: {
    textAlign: "left",
    padding: theme.spacing(2),
  },
  // divider: {
  // margin: `${theme.spacing(3)}px 0`,
  // },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
}));

const Landing = () => {
  const classes = useStyles();

  return (
    <div>
      <h1>Landing</h1>
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          justify="space-around"
          alignItems="baseline"
        >
          <Grid item xs={12} sm={6} md={4} align="center">
            <ShowCard classes={classes} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} align="center">
            <ShowCard classes={classes} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} align="center">
            <ShowCard classes={classes} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} align="center">
            <ShowCard classes={classes} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} align="center">
            <ShowCard classes={classes} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} align="center">
            <ShowCard classes={classes} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const ShowCard = ({ classes }) => (
  <Card className={classes.card}>
    <CardActionArea>
      <CardMedia
        className={classes.media}
        image="https://4.bp.blogspot.com/-1b-2wntvYw8/XYUg5u8n_VI/AAAAAAAAN4I/_2UaXoHLxc8JgVBYAJ6PwaJczs55Q3ChwCLcBGAsYHQ/w217/sunset-stars-landscape-scenery-uhdpaper.com-4K-4.753-wp.thumbnail.jpg"
        title="Deck Name"
      />
      <CardContent className={classes.content}>
        <Typography
          gutterBottom
          variant="h5"
          className={classes.heading}
          component="h2"
        >
          Deck Name
        </Typography>
        <Typography
          variant="caption"
          className={classes.subheading}
          color="textSecondary"
          component="p"
        >
          Deck Description
        </Typography>
      </CardContent>
    </CardActionArea>
    {/* <Divider className={classes.divider} light /> */}
    <CardActions className={classes.center}>
      <Button size="small" color="primary">
        Add to Cart
      </Button>
    </CardActions>
  </Card>
);

export default Landing;
