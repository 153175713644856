import React from "react";
import { Link, useLocation } from "react-router-dom";

import { AuthUserContext } from "../Session";
import SignOutButton from "../SignOut";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  grow: { flexGrow: 1 },
  title: {
    fontWeight: "bold",
    marginRight: theme.spacing(2),
  },
  link: {
    margin: `0px ${theme.spacing(1)}px`,
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    "&:active, &:focus": {
      outline: 0,
    },
  },
  desktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  mobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menuList: {
    backgroundColor: theme.palette.secondary.main,
  },
  mobileLink: {
    padding: theme.spacing(1),
    width: "100%",
    textDecoration: "none",
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:active, &:focus": {
      outline: 0,
    },
  },
  fullWidth: {
    width: "100%",
  },
  menuItem: {
    padding: "0px",
  },
  selected: {
    color: theme.palette.secondary.light,
    // color: "#ffffff",
  },
  selectedMobile: {
    color: theme.palette.primary.dark,
    // color: "#ffffff",
  },
  offset: theme.mixins.toolbar,
}));

const Navigation = () => {
  const classes = useStyles();
  const location = useLocation().pathname;

  const selected = (route) => route === location; //? { color: "#CCCCCC" } : {});

  return (
    <AuthUserContext.Consumer>
      {(authUser) =>
        authUser ? (
          <NavigationAuth
            authUser={authUser}
            classes={classes}
            selected={selected}
          />
        ) : (
          <NavigationNonAuth classes={classes} selected={selected} />
        )
      }
    </AuthUserContext.Consumer>
  );
};

const NavigationAuth = ({ authUser, classes, selected }) => {
  const [mobileMenu, setMobileMenu] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMenu);

  const handleMobileMenuClose = () => {
    setMobileMenu(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenu(event.currentTarget);
  };

  const mobileMenuId = "mobile-menu";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenu}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      MenuListProps={{ className: classes.menuList }}
    >
      <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
        <ButtonBase className={classes.fullWidth}>
          <Typography
            variant="body1"
            className={`${classes.mobileLink} ${
              selected(ROUTES.HOME) ? classes.selectedMobile : ""
            }`}
            component={Link}
            to={ROUTES.HOME}
          >
            Home
          </Typography>
        </ButtonBase>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
        <ButtonBase className={classes.fullWidth}>
          <Typography
            variant="body1"
            className={`${classes.mobileLink} ${
              selected(ROUTES.STORE) ? classes.selectedMobile : ""
            }`}
            component={Link}
            to={ROUTES.STORE}
          >
            Store
          </Typography>
        </ButtonBase>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
        <ButtonBase className={classes.fullWidth}>
          <Typography
            variant="body1"
            className={`${classes.mobileLink} ${
              selected(ROUTES.ACCOUNT) ? classes.selectedMobile : ""
            }`}
            component={Link}
            to={ROUTES.ACCOUNT}
          >
            Account
          </Typography>
        </ButtonBase>
      </MenuItem>
      {!!authUser.roles[ROLES.ADMIN] && (
        <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
          <ButtonBase className={classes.fullWidth}>
            <Typography
              variant="body1"
              className={`${classes.mobileLink} ${
                selected(ROUTES.ADMIN) ? classes.selectedMobile : ""
              }`}
              component={Link}
              to={ROUTES.ADMIN}
            >
              Admin
            </Typography>
          </ButtonBase>
        </MenuItem>
      )}
      {!!authUser.roles[ROLES.ADMIN] && (
        <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
          <ButtonBase className={classes.fullWidth}>
            <Typography
              variant="body1"
              className={`${classes.mobileLink} ${
                selected(ROUTES.NEW_ITEM) ? classes.selectedMobile : ""
              }`}
              component={Link}
              to={ROUTES.NEW_ITEM}
            >
              Add New Item
            </Typography>
          </ButtonBase>
        </MenuItem>
      )}
      <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
        <ButtonBase className={classes.fullWidth}>
          <SignOutButton mobile={true} />
        </ButtonBase>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          <ButtonBase>
            <Typography
              variant="h4"
              className={`${classes.title} ${classes.link} ${
                selected(ROUTES.LANDING) ? classes.selected : ""
              }`}
              component={Link}
              to={ROUTES.LANDING}
            >
              cardi.st
            </Typography>
          </ButtonBase>
          <div className={classes.desktop}>
            <ButtonBase>
              <Typography
                variant="body1"
                className={`${classes.link} ${
                  selected(ROUTES.HOME) ? classes.selected : ""
                }`}
                component={Link}
                to={ROUTES.HOME}
              >
                Home
              </Typography>
            </ButtonBase>
            <ButtonBase>
              <Typography
                variant="body1"
                className={`${classes.link} ${
                  selected(ROUTES.STORE) ? classes.selected : ""
                }`}
                component={Link}
                to={ROUTES.STORE}
              >
                Store
              </Typography>
            </ButtonBase>
            <ButtonBase>
              <Typography
                variant="body1"
                className={`${classes.link} ${
                  selected(ROUTES.ACCOUNT) ? classes.selected : ""
                }`}
                component={Link}
                to={ROUTES.ACCOUNT}
              >
                Account
              </Typography>
            </ButtonBase>
            {!!authUser.roles[ROLES.ADMIN] && (
              <ButtonBase>
                <Typography
                  variant="body1"
                  className={`${classes.link} ${
                    selected(ROUTES.ADMIN) ? classes.selected : ""
                  }`}
                  component={Link}
                  to={ROUTES.ADMIN}
                >
                  Admin
                </Typography>
              </ButtonBase>
            )}
            {!!authUser.roles[ROLES.ADMIN] && (
              <ButtonBase>
                <Typography
                  variant="body1"
                  className={`${classes.link} ${
                    selected(ROUTES.NEW_ITEM) ? classes.selected : ""
                  }`}
                  component={Link}
                  to={ROUTES.NEW_ITEM}
                >
                  Add New Item
                </Typography>
              </ButtonBase>
            )}
          </div>
          <div className={classes.grow} />
          <div className={classes.desktop}>
            <ButtonBase>
              <SignOutButton />
            </ButtonBase>
          </div>
          <div className={classes.mobile}>
            <IconButton
              aria-label="Open Drawer"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <div className={classes.offset} />
    </div>
  );
};

const NavigationNonAuth = ({ classes, selected }) => {
  const [mobileMenu, setMobileMenu] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMenu);

  const handleMobileMenuClose = () => {
    setMobileMenu(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenu(event.currentTarget);
  };

  const mobileMenuId = "mobile-menu";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenu}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      MenuListProps={{ className: classes.menuList }}
    >
      <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
        <ButtonBase className={classes.fullWidth}>
          <Typography
            variant="body1"
            className={`${classes.mobileLink} ${
              selected(ROUTES.STORE) ? classes.selectedMobile : ""
            }`}
            component={Link}
            to={ROUTES.STORE}
          >
            Store
          </Typography>
        </ButtonBase>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose} className={classes.menuItem}>
        <ButtonBase>
          <Typography
            variant="body1"
            className={`${classes.mobileLink} ${
              selected(ROUTES.SIGN_IN) ? classes.selectedMobile : ""
            }`}
            component={Link}
            to={ROUTES.SIGN_IN}
          >
            Sign In
          </Typography>
        </ButtonBase>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar>
          <ButtonBase>
            <Typography
              variant="h4"
              className={`${classes.title} ${classes.link} ${
                selected(ROUTES.LANDING) ? classes.selected : ""
              }`}
              component={Link}
              to={ROUTES.LANDING}
            >
              cardi.st
            </Typography>
          </ButtonBase>
          <div className={classes.desktop}></div>
          <div className={classes.grow}></div>
          <div className={classes.desktop}>
            <ButtonBase>
              <Typography
                variant="body1"
                className={`${classes.link} ${
                  selected(ROUTES.SIGN_IN) ? classes.selected : ""
                }`}
                component={Link}
                to={ROUTES.SIGN_IN}
              >
                Sign In
              </Typography>
            </ButtonBase>
          </div>
          <div className={classes.mobile}>
            <IconButton
              aria-label="Open Drawer"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <div className={classes.offset} />
    </div>
  );
};

export default Navigation;
