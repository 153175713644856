import React from "react";

import { withFirebase } from "../Firebase";

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null,
};

const PasswordChangeForm = (props) => {
  const [formData, setFormData] = React.useState(INITIAL_STATE);

  const onSubmit = (event) => {
    props.firebase
      .doPasswordChange(formData.passwordOne)
      .then(() => {
        setFormData({ ...INITIAL_STATE });
      })
      .catch((error) => {
        setFormData({ ...formData, error });
      });

    event.preventDefault();
  };

  const onChange = (event) =>
    setFormData({ ...formData, [event.target.name]: event.target.value });

  const isInvalid =
    formData.passwordOne !== formData.passwordTwo ||
    formData.passwordOne === "";

  return (
    <form onSubmit={onSubmit}>
      <input
        name="passwordOne"
        value={formData.passwordOne}
        onChange={onChange}
        type="password"
        placeholder="New Password"
      />
      <input
        name="passwordTwo"
        value={formData.passwordTwo}
        onChange={onChange}
        type="password"
        placeholder="Confirm New Password"
      />
      <button disabled={isInvalid} type="submit">
        Reset My Password
      </button>

      {formData.error && <p>{formData.error.message}</p>}
    </form>
  );
};

export default withFirebase(PasswordChangeForm);
