import React from "react";
import { withFirebase } from "../Firebase";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  button: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      color: theme.palette.secondary.main,
      cursor: "pointer",
    },
    "&:active, &:focus": {
      outline: 0,
    },
  },
  mobileLink: {
    padding: theme.spacing(1),
    width: "100%",
    textDecoration: "none",
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:active, &:focus": {
      outline: 0,
    },
  },
}));

const SignOutButton = (props) => {
  const classes = useStyles();

  return (
    <Typography
      variant="body1"
      className={`${classes.button} ${props.mobile ? classes.mobileLink : ""}`}
      onClick={props.firebase.doSignOut}
    >
      Sign Out
    </Typography>
  );
};

export default withFirebase(SignOutButton);
