export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_RESET = "/passwordreset";
export const ADMIN_DETAILS = "/admin/:id";
export const NEW_ITEM = "/newitem";
export const STORE = "/store";
